"use client";
import React, { useState, useEffect, useRef } from "react";
import { InputGroup, Dropdown, Form, Button } from "@/components/bootstrap";
import { MicIcon, SearchIcon } from "@/utils/icons";
import { fetchElasticSearch } from "@/utils/apiServices";
import { SearchBar } from "../common";
import { useSettingsContext } from "@/context";
import SearchSection from "./search-section";
import SearchTopSection from "./search-top-section";
import { SearchDropdownItem, SearchSectionProps } from "../../utils/types";
import { useRouter } from "@/navigation";
import { useTranslations } from "next-intl";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { BsXLg } from "react-icons/bs";

interface SearchFormProps {
  searchTopCards: SearchSectionProps;
  searchCategory: SearchSectionProps;
  searchDropdown?: SearchDropdownItem[];
}
function SearchForm({
  searchTopCards,
  searchCategory,
  searchDropdown = [],
}: SearchFormProps) {
  const searchText = useTranslations("searchText")
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const router = useRouter();
  const [searchBool, setSearchBool] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [elasticSearchRes, setElasticSearchRes] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [listening, setListening] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>(
    searchDropdown[0]?.title || "Marketplace"
  );
  const { themeDirection } = useSettingsContext();
  const isRtl = themeDirection === "rtl";

  const searchResultRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const searchVoiceRef = useRef<HTMLInputElement | null>(null);
  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      setSelectedValue(eventKey);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();

      recognition.continuous = false;
      recognition.interimResults = false;

      recognition.onresult = (event: any) => {
        const query = event.results[0][0].transcript;
        setSearchValue(query);
        setListening(false);
      };

      recognition.onerror = (event: any) => {
        console.error(event.error);
        setListening(false);
      };

      const startListening = () => {
        setListening(true);
        recognition.start();
      };

      const stopListening = () => {
        setListening(false);
        recognition.stop();
      };

      window.startListening = startListening;
      window.stopListening = stopListening;
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchResultRef.current &&
        !searchResultRef.current?.contains(event.target as Node) &&
        searchInputRef.current &&
        !searchInputRef.current?.contains(event.target as Node) &&
        searchVoiceRef.current &&
        !searchVoiceRef.current?.contains(event.target as Node)
      ) {
        setSearchBool(false);
        setSearchValue("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (searchValue.length > 0) {
        try {
          setLoading(true);
          const response = await fetchElasticSearch(searchValue);
          setElasticSearchRes(response);
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    const debounceTimeout = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => clearTimeout(debounceTimeout);
  }, [searchValue]);

  const handleSearchValue = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleSearchClick = (e: any) => {
    setSearchBool(true);
  };
  const handleBtnClick = () => {
    if (searchValue) {
      router.push(`/category-search/${searchValue}`);
    }
  };

  return (
    <div
    className={`search-container  position-absolute start-50 translate-middle ${searchBool ? "z-index-1" : ""} ${
      isMobile ? "px-2 top-80" : "top-60"
    } px-md-5 d-md-block`}
  >
      <InputGroup
        className={` shadow-adding
          ${searchBool
            ? isMobile
              ? "search-true-mobile"
              : "search-true"
            : ""}
        `}
        ref={searchInputRef}
      >
        {!searchBool && !isMobile && (
          <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
              {selectedValue}
            </Dropdown.Toggle>

            <Dropdown.Menu className="border-0 shadow">
              {searchDropdown?.map((item: any) => (
                <Dropdown.Item key={item.id} eventKey={item.title}>
                  {item.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}

        <Form.Control
          aria-label="Search supplier, experts, products etc"
          aria-describedby="basic-addon1"
          placeholder={searchText("search_supplier_experts_products_etc")}
          onClick={handleSearchClick}
          onChange={handleSearchValue}
          onFocus={handleSearchClick}
          value={searchValue}
          style={
            searchBool || isMobile
              ? {
                  borderTopLeftRadius: isRtl ? "0" : "38px",
                  borderBottomLeftRadius: isRtl ? "0" : "38px",
                  borderTopRightRadius: isRtl ? "38px" : "0",
                  borderBottomRightRadius: isRtl ? "38px" : "0",
                  // borderTop: "1px solid #E6E6E6",
                  // borderLeft: isRtl ? "none" : "1px solid #E6E6E6",
                  // borderRight: isRtl ? "1px solid #E6E6E6" : "none",
                }
              : {
                  // borderLeft: isRtl ? "none" : "1px solid #E6E6E6",
                  // borderRight: isRtl ? "1px solid #E6E6E6" : "none",
                }
          }
        />
        {/* {searchBool && ( */}
        <div className="d-flex align-items-center gap-md-4 gap-2  bg-white">

          <div
            ref={searchVoiceRef}
            className="text-black p-3 bg-white voice-btn cursor-pointer"
            style={
              searchBool || isMobile
                ? {
                    // borderTop: "1px solid #E6E6E6",
                    // borderRight: isRtl ? "1px solid #E6E6E6" : "none",
                  }
                : {}
            }
            onClick={() =>
              listening ? window.stopListening() : window.startListening()
            }
          >
            <MicIcon />
          </div>
          {searchBool && <div className="p-2 me-2 d-sm-none d-block">
          <BsXLg size={20} onClick={()=>setSearchBool(false)} className="cursor-pointer"/>
          </div>}
          </div>
        {/* )} */}

        <Button
          aria-label="search-name"
          variant="outline-primary search-btn d-flex justify-content-center align-items-center"
          style={
            isRtl
              ? {
                  borderTopLeftRadius: "38px",
                  borderBottomLeftRadius: "38px",
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                }
              : {
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  borderTopRightRadius: "38px",
                  borderBottomRightRadius: "38px",
                }
          }
          onClick={handleBtnClick}
        >
          {!searchBool && searchText("search_text")}
          <span className="bi-search">
            <SearchIcon />
          </span>
        </Button>
      </InputGroup>

      {searchBool && (
        <div
          className="p-md-3 mb-5 bg-body px-md-3"
          style={{
            opacity: 1,
            margin: "auto",
            width: isMobile ? "100%" :" 95%",
          }}
        >
          {/* <div className="search-bar-container shadow position-absolute top-0 start-50 translate-middle w-100 d-none d-md-block rounded-5">
            <SearchBar />
          </div> */}

          {!searchValue && (
            <div ref={searchResultRef}>
              {" "}
              <SearchTopSection
                searchTopCards={searchTopCards}
                searchCategory={searchCategory}
              />{" "}
            </div>
          )}

          {searchValue && (
            <div ref={searchResultRef}>
              {" "}
              <SearchSection
                loading={loading}
                data={elasticSearchRes}
                searchValue={searchValue}
                selectedValue={selectedValue}
              />{" "}
            </div>
          )}
        </div>
      )}
            </div>
  );
}

export default SearchForm;
