"use client";
import { FC, useEffect, useState } from "react";
import SearchForm from "../advanced-search/SearchForm";
import { HeroSectionProps } from "@/utils/types";
import { BackgroundLayoutHome } from "../common";
import { Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";

const HeroSectionHome: FC<HeroSectionProps> = ({
  heading,
  subheading,
  content,
  showSearch,
  containerHeight,
  bgHeight,
  sectionHeight,
  children,
  searchCategory,
  searchTopCards,
  searchDropdown,
  isButton,
  buttonText,
  redirectionUrl
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoading, SetIsLoading] = useState(true);
  const isMobile = useClientMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (searchCategory) {
      SetIsLoading(false);
    }
  }, [searchCategory]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <div
      className={`${
        containerHeight ? containerHeight : "vh-150"
      } home-hero-widget position-relative overflow-hidden`}
    >
      <div
        className={`hero-content d-flex flex-column mvertical-10 justify-content-center align-items-center ${
          isMobile ? "w-100" : "w-75"
        } m-auto`}
      >
        { heading || "" }
        {subheading ? subheading : "" }
        {content ? content : ""}
        {isButton &&  <Button
                type="button"
                className="rounded-5 d-sm-block secondary_btn d-none get-started-btn my-4 text-white"
                size="lg"
                href={redirectionUrl}
              >
                {buttonText}
              </Button>}
      </div>


      {showSearch && (

        <SearchForm
            searchTopCards={searchTopCards}
            searchCategory={searchCategory}
            searchDropdown={searchDropdown}
          />
      )}

      {!!children && children}
    </div>
  );
};

const PlaceholderComponent = ({ minHeight }: any) => (
  <div
    style={{
      minHeight,
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  ></div>
);

export default HeroSectionHome;
