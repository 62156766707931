"use client";
import { FC } from "react";
import { SearchButton, SearchProductsCard } from "../common";
import { SearchApiProps } from "@/utils/types";

type SearchSectionProps = {
  data: SearchApiProps;
  searchValue: string;
  loading: boolean;
  selectedValue?: string;
};

const SearchSection: FC<SearchSectionProps> = ({
  data,
  searchValue,
  loading,
  selectedValue,
}) => {
  const excludeKeys = [
    "buyer_count",
    "expert_count",
    "product_count",
    "supplier_count",
  ];

  const getFilteredData = () => {
    if (selectedValue === "Marketplace") {
      return Object.entries(data).filter(([key]) => key === "product");
    } else if (selectedValue === "Network") {
      return Object.entries(data).filter(([key]) =>
        ["supplier", "buyer", "expert"].includes(key)
      );
    }
  };
  const filteredData = getFilteredData();
  return (
    <div className="custom-search-css">
      <div className="custom-search-cards">
        <h4 className="fw-light fs-4 mb-2 mt-3">
          What type of <span className="fw-bold">{searchValue}</span> are you
          looking for ?
        </h4>

        <div>
          {filteredData?.length === 0 && !loading ? (
            <div className="text-center">
          <img src="./assets/img/empty_data.png" width={100} alt="no data found" />
          <h4 className="mt-5 fw-semibold text-center">We couldn't find any matches!</h4>
          <p className="text-center">Please check the spelling or try searching something else</p>
          </div>
          ) : (
            filteredData?.map(([key, value]) => {
              let countItems = 0;
              if (key === "buyer") {
                countItems = data.buyer_count;
              } else if (key === "supplier") {
                countItems = data.supplier_count;
              } else if (key === "expert") {
                countItems = data.expert_count;
              } else if (key === "product") {
                countItems = data.product_count;
              }

              return (
                <SearchProductsCard
                  key={key}
                  title={key.charAt(0).toUpperCase() + key.slice(1)}
                  content={`Over ${countItems}`}
                  data={value}
                  searchValue={searchValue}
                />
              );
            })
          )}
        </div>
        <SearchButton />
      </div>
    </div>
  );
};

export default SearchSection;
